<template>
    <vue-table-card :showSearchBox="false" class="m-3 c-table" title="Contact Us" :fields="fields" :url="listUrl"
                    :per-page="5"
                    ref="table">
        <template #buttons>
            <btn icon="fa fa-plus" size="sm" @click="showAddModal" text="Add"/>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn color="primary" text="Edit" icon="" size="xs" @click="setEdit(rowData)"/>
                <delete-btn icon="" size="xs" @click="setDelete(rowData)"/>
            </div>
        </template>
        <template #status="{rowData}">
            <p v-if="rowData.status==='published'">Published</p>
            <p v-else>UnPublished</p>
        </template>

        <template #footer>
            <modal no-close-on-backdrop title="Add Contacts" ref="addModal" width="40r" header-color="primary">
                <s-form @submit="submitClicked('add')" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-input label="SS" name="SS" v-model="model.semenstation"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Name" name="Name" v-model="model.name"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Land Line" name="Land Line" v-model="model.phone"
                                             :rules="{required : false}"
                                             :disabled="loading" type="number"/>
                        </div>
                        <div class="col">
                            <validated-input label="Mobile" name="Mobile" v-model="model.mobile"
                                             :rules="{required : false}"
                                             :disabled="loading" type="number"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Pin code" name="Pin code" v-model="model.pincode"
                                             :rules="{required : true}"
                                             :disabled="loading" type="number"/>
                        </div>
                        <div class="col">
                            <validated-input label="E-Mail" name="E-Mail" v-model="model.email"
                                             :rules="{required : true}"
                                             :disabled="loading" type="email"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-vue-select label="Designation" name="Designation" v-model="model.designation"
                                             :rules="{required : true}" :options="designationOptions" :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Rank" name="Rank" v-model="model.rank"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Latitude" name="Latitude" v-model="model.latitude"
                                             :rules="{required : false}"
                                             :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Longitude" name="Longitude" v-model="model.longitude"
                                             :rules="{required : false}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Save" :loading="loading" :disabled="loading" loading-text="Saving.."/>
                </s-form>
            </modal>

            <modal no-close-on-backdrop title="Edit Contacts" ref="editModal" width="40r" header-color="primary">
                <s-form @submit="submitClicked('edit')" ref="form">
                    <div class="row">
                        <div class="col">
                            <validated-input label="SS" name="SS" v-model="model.semenstation"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Name" name="Name" v-model="model.name"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Land Line" name="Land Line" v-model="model.phone"
                                             :rules="{required : false}"
                                             :disabled="loading" type="number"/>
                        </div>
                        <div class="col">
                            <validated-input label="Mobile" name="Mobile" v-model="model.mobile"
                                             :rules="{required : false}"
                                             :disabled="loading" type="number"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Pin code" name="Pin code" v-model="model.pincode"
                                             :rules="{required : true}"
                                             :disabled="loading" type="number"/>
                        </div>
                        <div class="col">
                            <validated-input label="E-Mail" name="E-Mail" v-model="model.email"
                                             :rules="{required : true}"
                                             :disabled="loading" type="email"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-vue-select label="Designation" name="Designation" v-model="model.designation"
                                             :rules="{required : true}" :options="designationOptions" :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Rank" name="Rank" v-model="model.rank"
                                             :rules="{required : true}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <validated-input label="Latitude" name="Latitude" v-model="model.latitude"
                                             :rules="{required : false}"
                                             :disabled="loading"/>
                        </div>
                        <div class="col">
                            <validated-input label="Longitude" name="Longitude" v-model="model.longitude"
                                             :rules="{required : false}"
                                             :disabled="loading"/>
                        </div>
                    </div>
                    <btn size="sm" text="Update" :loading="loading" :disabled="loading" loading-text="Updating.."/>
                </s-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteSuccess">
                <p>You are about to delete the item. Are you sure?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete the item.
                </template>
            </delete-modal>
            <modal ref="confirmModal" title="Confirm" class="text-center" width="30r"
                   no-close-on-backdrop>
                <p class="text-left">The rank you entered already exists, Do you wish to replace the existing contact
                    ?</p>
                <div class="fl-x-br">
                    <btn size="xs" @click="swapValue()" class="w-20p ml-2" text="Ok"/>
                </div>
            </modal>
        </template>

    </vue-table-card>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'ContactUsListing',
    data () {
        return {
            loading: false,
            listUrl: urls.admin.contact.list,
            deleteUrl: urls.admin.contact.delete,
            addUrl: urls.admin.contact.add,
            editUrl: urls.admin.contact.edit,
            designationOptions: [
                { value: 'Assistant Manager (AH)', label: 'Assistant Manager (AH)' },
                { value: 'Dy. Manager (AH)', label: 'Dy. Manager (AH)' }
            ],
            statusOption: [
                { value: 'published', label: 'Published' },
                { value: 'unpublished', label: 'UnPublished' }
            ],
            deletingItem: {
                id: ''
            },
            fields: [
                {
                    name: 'slno',
                    title: 'No'
                },
                {
                    name: 'semenstation',
                    title: 'SS'
                },
                {
                    name: 'name',
                    title: 'name'
                },
                {
                    name: 'email',
                    title: 'E-Mail'
                },
                {
                    name: 'mobile',
                    title: 'Mobile'
                },
                {
                    name: 'rank',
                    title: 'Rank'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            model: {
                name: ''
            },
            actionClicked: ''
        };
    },
    methods: {
        showAddModal () {
            this.confirm = false;
            this.model = {};
            this.$refs.addModal.show();
        },
        setEdit (item) {
            this.confirm = false;
            this.model = { ...item };
            this.$refs.editModal.show();
        },
        setDelete (item) {
            this.deletingItem.id = item.id;
            this.$refs.deleteModal.show();
        },
        async submitClicked (action) {
            const that = this;
            that.model.interchange = that.confirm;
            that.loading = true;
            action = that.setAction(action);
            const response = await axios.form(that.action, that.model);
            const json = response.data;
            if (json.error === false) {
                that.selectSuccess(action);
                that.loading = false;
            } else {
                that.formError(json, action);
                that.loading = false;
            }
            that.loading = false;
        },
        setAction (action) {
            if (action === '') {
                action = this.actionClicked;
            }
            if (action === 'add') {
                this.action = this.addUrl;
            }
            if (action === 'edit') {
                this.action = this.editUrl;
            }
            return action;
        },
        selectSuccess (action) {
            if (action === 'add') {
                this.formSuccess();
            }
            if (action === 'edit') {
                this.formSuccess1();
            }
            if (action === undefined) {
                this.formSuccess1();
            }
        },
        formSuccess () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Contacts Saved Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formSuccess1 () {
            this.$refs.addModal.close();
            this.$refs.editModal.close();
            this.$notify('Contacts Updated Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        deleteSuccess () {
            this.$refs.deleteModal.close();
            this.deletingItem.id = '';
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        },
        formError (json, action) {
            if (json.errors.Rank === 'Rank already exists') {
                this.actionClicked = action;
                this.$refs.confirmModal.show();
            }
            this.$refs.form.setErrors(json.errors);
        },
        swapValue () {
            this.confirm = true;
            this.$refs.confirmModal.close();
            this.submitClicked();
        }
    }
};
</script>

<style scoped>

</style>
